import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
const query = graphql`
  {
    allMdx {
      distinct(field: frontmatter___category)
    }
  }
`

const Categories = () => {
  const {
    allMdx: { distinct },
  } = useStaticQuery(query)
  return (
    <ul className="categories__list">
      {distinct.map((category, index) => {
        return (
          <li key={index} className="categories__tag">
            <Link to={`/${category}`} className="category">
              <span className="tag article__tag article__tag" id={category}>{category}</span>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Categories
