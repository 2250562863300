import React from "react"
import {
  FaGithub,
  FaLinkedinIn,
} from "react-icons/fa"
const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li className="navbar-item">
        <a href="https://github.com/jenny-q" title="github" target="_blank" rel="noopener noreferrer">
          <FaGithub className="social-icon" aria-hidden="true" tabIndex="-1"></FaGithub>
        </a>
      </li>
      <li className="navbar-item">
        <a href="https://linkedin.com/in/jenniferquispe" title="linkedin" target="_blank" rel="noopener noreferrer">
          <FaLinkedinIn className="social-icon" aria-hidden="true" tabIndex="-1"></FaLinkedinIn>
        </a>
      </li>
    </ul>
  )
}
export default SocialLinks
