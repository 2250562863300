import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className="footer">
        <StaticImage
          src="../assets/peru32.png"
          width={32}
          height={32}
          alt=""
          className="footer__img"
        />
        <p>&copy;{new Date().getFullYear()} Jennifer Quispe. All rights reserved.</p>
        <Link to="/privacypolicy" className="ext-links">
            Privacy Policy
        </Link>
    </footer>
  )
}

export default Footer
