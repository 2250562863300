import React from 'react'
import { Link } from 'gatsby'
import SocialLinks from '../constants/socialLinks'
const Navbar = ({ toggle }) => {
  return (
    <nav className="navbar is-white is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item nav-logo space">
            jennifer quispe
          </Link>
        </div>
        <div className="navbar-menu">
          <SocialLinks styleClass="navbar-end" />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
